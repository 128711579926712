<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <!-- 输入任意文本 -->
    <van-field
      v-model="receiver"
      label-align="left"
      label="收货人"
      placeholder="请输入真实姓名"
    />
    <!-- 输入手机号，调起手机号键盘 -->
    <van-field
      v-model="mobile"
      label-align="left"
      maxlength="11"
      type="tel"
      label="手机号码"
      placeholder="请输入手机号"
    />
    <div class="sele">
      <span class="lable">选择省</span>
      <select v-model="sheng_name" @change="sele_change_sheng()">
        <option value="">请选择</option>
        <option v-for="(item,index) in address_list_sheng" :key="index" @click="getShengId(item)">{{ index }}</option>
      </select>
    </div>
    <div class="sele">
      <span class="lable">选择市</span>
      <select v-model="shi_name" @change="sele_change_shi()">
        <option value="">请选择</option>
        <option v-for="(item,index) in address_list_shi" :key="index">{{ index }}</option>
      </select>
    </div>
    <div class="sele">
      <span class="lable">选择区</span>
      <select v-model="qu_name" @change="sele_change_qu()">
        <option value="">请选择</option>
        <option v-for="(item,index) in address_list_qu" :key="index">{{ index }}</option>
      </select>
    </div>
    <div class="sele">
      <span class="lable">选择街道</span>
      <select v-model="town_name" @change="sele_change_Town()">
        <option value="">请选择</option>
        <option v-for="(item,index) in address_list_Town" :key="index">{{ index }}</option>
      </select>
    </div>

    <!-- 输入任意文本 -->
    <van-field
      v-model="text"
      label-align="left"
      label="详细地址"
      placeholder="道路、小区、单元室、小区门牌等"
    />
    <van-button
      type="danger"
      round
      class="btn_submit"
      block
      @click="add_address()"
    >{{ one_id?'修改地址':'添加新地址' }}</van-button>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { areaList } from '@vant/area-data'
import { getJdProvince, getJdShi, addjdAddress, getJdQu, getJdTown, getOneAddress } from '@/api/my_user.js'
import { Toast } from 'vant'
export default {
  components: {
    heads
  },
  data() {
    return {
      one_id: this.$route.query.one_id || '',
      text: '',
      areaList: areaList,
      value: '',
      showArea: false,
      mobile: '',
      title_msg: '添加地址',
      reg: new RegExp(/^[1]+[3-9]+\d{9}$/g),
      receiver: '',
      show: false,
      fieldValue: '',
      cascaderValue: '',
      address_list_sheng: [], // 省列表
      // 选项列表，children 代表子选项，支持多级嵌套
      sheng_name: '', // 省名称
      ssq: '福建省/莆田市/城厢区',
      shengId: '', // 省ID
      address_list_shi: [],
      shi_name: '', // 市名称
      address_list_qu: [],
      shiId: '',
      quId: '',
      qu_name: '',
      address_list_Town: [],
      town_name: '',
      town_id: ''
    }
  },
  mounted() {
    if (this.one_id) {
      this.getOneAddress()
    } else {
      this.receiver = ''
      this.mobile = ''
      this.sheng_name = ''
      this.shi_name = ''
      this.town_name = ''
      this.qu_name = ''
      this.text = ''
      this.shengId = ''
      this.shiId = ''
      this.quId = ''
      this.town_id = ''
    }
    this.getsheng()
  },

  methods: {
    // 修改地址回显
    getOneAddress() {
      const info = {
        id: this.one_id
      }
      getOneAddress(info).then(res => {
        if (res.errNo == 0) {
          var arr = res.result
          this.receiver = res.result.receiver
          this.mobile = res.result.mobile
          this.text = res.result.detail_address
          this.sheng_name = arr.address_json.province
          this.shengId = res.result.province_id
        }
        getJdShi({ id: this.shengId }).then((res) => {
          var arrCity = []
          for (const i in res.result) {
            arrCity.push(res.result[i])
          }
          if (arrCity.length == 1) {
            this.shiId = arr.province_id
            this.quId = arr.city_id
            this.town_id = arr.county_id
            this.shi_name = arr.address_json.province
            this.qu_name = arr.address_json.city
            this.town_name = arr.address_json.county
          } else {
            this.shiId = arr.city_id
            this.quId = arr.county_id
            this.town_id = arr.town_id
            this.shi_name = arr.address_json.city
            this.qu_name = arr.address_json.county
            this.town_name = arr.address_json.town
          }
          this.address_list_shi = res.result
        }).then(() => {
          getJdQu({ id: this.shiId }).then((res1) => {
            this.address_list_qu = res1.result
          })
        }).then(() => {
          getJdTown({ id: this.quId }).then(res2 => {
            this.address_list_Town = res2.result
          })
        })
      })
    },
    // sele_change_Town
    sele_change_Town() {
      this.townName = this.town_name
      this.town_id = this.address_list_Town[this.town_name]
    },
    // 获得区ID
    sele_change_qu() {
      this.quId = this.address_list_qu[this.qu_name]
      this.getTown()
    },
    getTown() {
      var params = {
        id: this.quId
      }
      getJdTown(params).then(res => {
        console.log(res)
        this.address_list_Town = res.result
      })
    },
    // 获得市ID
    sele_change_shi() {
      this.shiId = this.address_list_shi[this.shi_name]
      this.getqu()
    },
    // get_sheng_id
    getShengId() {
      // console.log(item, 9999)
      console.log(1111111)
    },
    // 获取省id
    sele_change_sheng() {
      console.log(this.sheng_name, 'this.sheng_name')
      // const obj = this.address_list_sheng.find(item => {
      //   if (this.sheng_name === item.cname) {
      //     return item
      //   }
      // })
      // console.log(obj)
      this.shengId = this.address_list_sheng[this.sheng_name]
      this.getshi()
    },
    // 获取省id对应的市列表
    getshi() {
      var params = {
        id: this.shengId
      }
      getJdShi(params).then((res) => {
        this.address_list_shi = res.result
        this.shi_name = ''
        this.qu_name = ''
        this.town_name = ''
        console.log(res)
      })
    },
    // 获取市id对应的区列表
    getqu() {
      var params = {
        id: this.shiId
      }
      getJdQu(params).then((res) => {
        this.address_list_qu = res.result
        this.qu_name = ''
        this.town_name = ''
        console.log(res)
      })
    },

    // 获取省
    getsheng() {
      getJdProvince().then((res) => {
        this.address_list_sheng = res.result
        console.log(res)
      })
    },
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.showArea = false
    },
    add_address() {
      console.log(this.receiver, this.mobile, this.shengId, this.shiId, this.quId, this.text)
      if (this.receiver && this.mobile && this.shengId && this.shiId && this.text) {
        if (this.reg.test(this.mobile) !== true) {
          Toast('无效的手机号')
          return
        }
        var params = {
          receiver: this.receiver,
          mobile: this.mobile,
          province_id: this.shengId,
          province: this.sheng_name,
          city_id: this.shiId,
          city: this.shi_name,
          town_id: this.town_id,
          town: this.town_name,
          id: this.one_id,
          county_id: this.quId ? this.quId : 0,
          county: this.qu_name ? this.qu_name : '',
          detail_address: this.text
        }
        addjdAddress(params).then(res => {
          console.log(res)
          if (res.errNo !== 0) {
            Toast(res.result)
          } else {
            Toast('添加成功')
            this.$router.replace({ path: '/jdAddressList' })
          }
        })
      } else {
        if (!this.receiver) {
          Toast.fail('请填写收件人')
        } else if (!this.mobile) {
          Toast.fail('请填写手机号')
        } else if (!this.shengId) {
          Toast.fail('请填写所在省市')
        } else if (!this.shiId) {
          Toast.fail('请填写所在省市')
        } else {
          Toast.fail('请填写详细地址')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sele{
  // position: relative;
  display: flex;
  padding: 0px 16px;
  justify-content: flex-start;
  .lable{
    width: 120px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-shrink:0;
    left: 64px;
    color: #333;
    font-size: 28px;
    font-weight: 700;
  }
}
/deep/ .van-field__label {
  color: #333;
  font-weight: 700;
  font-size: 28px;
}
// .sele span{
//   position: absolute;
//   bottom: 15px;
//   left: 64px;
//   color: #646566;
//   font-size: 30px;
//   display: block;
// }
select {
  -webkit-appearance: none;
  color: #333;
  height: 70px;
  width: 490px;
  margin-left: 220px;
  border: none;
  background: #fff;
  padding-left: 8px;
  margin: 8px 0 8px 64px;
  font-size: 28px;
}
option {
  background: #fff;
}
.btn_submit{
  width: 80%;
  background: #ff272c;
  position: absolute;
  left: 50%;
  margin-left: -40%;
  margin-bottom: 40px;
  bottom:0;
}
</style>
